import React, { Component } from 'react';
import './portfolio.css'; // Import the CSS file

export default class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFilter: 'All',
      isMobile: window.matchMedia("(max-width: 768px)").matches
    };
    this.sliderRefs = {};
  }

  setFilter = (category) => {
    this.setState({ currentFilter: category });
  }

  clearFilter = () => {
    this.setState({ currentFilter: 'All' });
  }
  
  handleResize = () => {
    this.setState({ isMobile: window.matchMedia("(max-width: 768px)").matches });
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  nextSlide = (slider) => {
    const slides = slider.querySelectorAll('.item-img');
    const activeSlide = slider.querySelector('.active');
    let nextSlide = activeSlide.nextElementSibling;
    if (!nextSlide || !nextSlide.classList.contains('item-img')) {
      nextSlide = slides[0];
    }
    activeSlide.classList.remove('active');
    nextSlide.classList.add('active');
  }

  prevSlide = (slider) => {
    const slides = slider.querySelectorAll('.item-img');
    const activeSlide = slider.querySelector('.active');
    let prevSlide = activeSlide.previousElementSibling;
    if (!prevSlide || !prevSlide.classList.contains('item-img')) {
      prevSlide = slides[slides.length - 1];
    }
    activeSlide.classList.remove('active');
    prevSlide.classList.add('active');
  }

  handleNextClick = (sliderId) => {
    this.nextSlide(this.sliderRefs[sliderId]);
  }

  handlePrevClick = (sliderId) => {
    this.prevSlide(this.sliderRefs[sliderId]);
  }

  render() {
    let resumeData = this.props.resumeData;
    let { currentFilter, isMobile } = this.state;

    return (
      <section id="portfolio">
        <div className="row">
          <h1>Check out some of my projects!</h1>
          <div className="filter-buttons">
            {
              resumeData.portfolio && resumeData.categories.map((item, id) => {
                return (
                  <button
                    className="btn-dark text-white p-1 px-2 mx-5 btn fw-bold"
                    onClick={() => this.setFilter(item.category)}
                    key={item.category}
                  >
                    {item.category}
                  </button>
                )
              })
            }
            <button
              className="btn-dark text-white p-1 px-3 mx-5 fw-bold btn"
              onClick={this.clearFilter}
              style={{ backgroundColor: 'rgba(250, 182, 35, 0.8)' }} // Inline CSS for yellow background
            >
              Clear Filters
            </button>
          </div>
          <div className="twelve columns collapsed">
            <div id="portfolio-wrapper" className="one-col-tab">
              {
                resumeData.portfolio && resumeData.portfolio.map((item) => {
                  const isHidden = !item.categories.map((categories) => categories.category).includes(currentFilter) && currentFilter !== 'All';
                  const sliderId = `portfolio-item-${item.name}`;
                  return (
                    <div className={`columns portfolio-item ${isHidden ? 'hidden' : ''}`} key={item.name}>
                      <div className="item-wrap">
                        <div className="portfolio-item-meta">
                          <h5>{item.name}</h5>
                          <p>{item.description}    Timeframe: {item.Timeframe}</p>
                          <p>{item.role}</p>
                          <p>------------------------------------------------------------------------------</p>
                          <p>Responsibilities:</p>
                          <p>    -{item.responsibilities1}</p>
                          <p>    -{item.responsibilities2}</p>
                          <p>    -{item.responsibilities3}</p>
                          <p>    -{item.responsibilities4}</p>
                          <a href={item.link} target="_blank">
                            <div className="overlayicon">
                              <img src={item.iconImg} />
                            </div>
                          </a>
                        </div>
                        <div
                          className={`portfolio-item-images ${isMobile ? 'mobile-layout' : ''}`}
                          ref={(el) => (this.sliderRefs[sliderId] = el)}
                        >
                          {
                            item.slides && item.slides.map((slide, index) => {
                              return (
                                <img src={`${slide.imgurl}`} className={`item-img ${index === 0 ? 'active' : ''}`} key={index} alt={`Slide ${index}`} />
                              )
                            })
                          }
                          <button className="prev" onClick={() => this.handlePrevClick(sliderId)}>&#10094;</button>
                          <button className="next" onClick={() => this.handleNextClick(sliderId)}>&#10095;</button>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </section>
    );
  }
}